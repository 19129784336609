<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Report/SalePage') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CButtonGroup class="mr-2">
              <CButton v-for="Type in ViewTypeOptions" :key="Type.value" size="sm" :color="(ViewType === Type.value ? 'danger' : 'light')"  @click="ViewType = Type.value">{{ Type.label }}</CButton>
            </CButtonGroup>
            <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Report.Action') }}
              </template>
              <CDropdownItem @click="Print()">
                {{ $t('Report.PrintCSV') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Origin')">
              結帳來源頁面: {{ SearchFilter.Origin.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Country')">
              {{ $t('Order.Country') }}: {{ SearchFilter.Country.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto">
        <CRow class="text-nowrap" style="min-width: 900px;">
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">團數</small><br>
              <strong class="h4">{{ Reporting.length || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">訂單數</small><br>
              <strong class="h4">{{ TotalStatistics.TotalItems || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">已收款金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.TotalAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="danger" class="my-1">
              <small class="text-muted">分潤金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalSaleReceiverAmount || 0) }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </CCardHeader>
      <template v-if="ViewType === 'Chart'">
        <CCardHeader class="w-100 overflow-auto">
          <CRow>
            <CCol col="12" class="mb-5">
              <CChartHorizontalBar :datasets="ChartDataSet" :labels="ChartLabels" />
            </CCol>
            <CCol col="12">
              <CChartDoughnut
                :datasets="ChartDoughnutDataSet"
                :options="ChartOptions"
                :labels="ChartPageLabels"
              />
            </CCol>
          </CRow>
        </CCardHeader>
      </template>
      <CCardBody v-if="ViewType === 'Table'" class="p-0">
        <CDataTable
          id="OrderSaleReport"
          :fields="Field"
          :items="Reporting"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #PageName="{item}">
            <td>
              <a href="javascript:void(0)" class="text-info" @click="GetOrderList(item._id)">{{ item.PageName }}</a>
            </td>
          </template>
          <template #TotalAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.TotalAmount) }}
            </td>
          </template>
          <template #PaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.PaymentAmount) }}
            </td>
          </template>
          <template #PaymentRealAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.PaymentRealAmount) }}
            </td>
          </template>
          <template #UnPaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.UnPaymentAmount) }}
            </td>
          </template>
          <template #ReturnPaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.ReturnPaymentAmount) }}
            </td>
          </template>
          <template #SalePaymentFee="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.SalePaymentFee) }}
            </td>
          </template>
          <template #SaleShipmentFee="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.SaleShipmentFee) }}
            </td>
          </template>
          <template #SaleReceiverAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.SaleReceiverAmount) }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Origin'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Origin') }}
        </CCol>
        <CCol sm="9">
          <CInputRadioGroup v-model="SearchFilterOriginMode" :options="[{value: 'IN', label: '一般搜尋'},{value: 'REGEX', label: '模糊搜尋'}]" :checked.sync="SearchFilterOriginMode" :custom="true" inline/>
          <multiselect
            v-if="SearchFilterOriginMode === 'IN'"
            id="SearchFilter.Origin"
            v-model="SearchFilter.Origin"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="PageList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
          <CInput v-if="SearchFilterOriginMode === 'REGEX'" type="text" v-model="SearchFilter.OriginRegex" horizontal />
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Country'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Country') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Country"
            v-model="SearchFilter.Country"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="CountryOptions"
            :multiple="true"
            :taggable="true"
            @select="ChangeCountry"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_id')" type="text" v-model="SearchFilter.utm_id" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_source')" type="text" v-model="SearchFilter.utm_source" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_medium')" type="text" v-model="SearchFilter.utm_medium" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_campaign')" type="text" v-model="SearchFilter.utm_campaign" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_term')" type="text" v-model="SearchFilter.utm_term" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_content')" type="text" v-model="SearchFilter.utm_content" horizontal />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="Init()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="SalePageDetailModal" class="ActionModel" size="xl" :show.sync="SalePageDetailModal" :centered="true">
      <div v-if="SalePageDetail.Data" class="mb-4 p-3 bg-light">
        <p class="mb-1">
          <b>活動時間：</b><span>{{ SalePageDetail.Data.Variable.StartTime ? $dayjs(SalePageDetail.Data.Variable.StartTime).format('YYYY-MM-DD HH:mm') : '--' }} ~ {{ SalePageDetail.Data.Variable.EndTime ? $dayjs(SalePageDetail.Data.Variable.EndTime).format('YYYY-MM-DD HH:mm') : '--' }}</span>
        </p>
        <p class="mb-1">
          <b>分潤比例：</b><span>{{ SalePageDetail.Data.Variable.CommissionPercentage || '0' }}%</span>
        </p>
        <p v-if="(SalePageDetail.Data.Variable.PaymentFeePercentage || []).length > 0" class="mb-1">
          <b>付款方式比例：</b>
          <span v-for="(Data, Index) in SalePageDetail.Data.Variable.PaymentFeePercentage" :key="Data.ID">{{ Data.Name || '--' }}（{{ FormatValue(Data) }}）<span v-if="Index + 1 !== SalePageDetail.Data.Variable.PaymentFeePercentage.length">、</span></span>
        </p>
        <p v-if="(SalePageDetail.Data.Variable.ShipmentFeePercentage || []).length > 0" class="mb-1">
          <b>配送方式比例：</b>
          <span v-for="(Data, Index) in SalePageDetail.Data.Variable.ShipmentFeePercentage" :key="Data.ID">{{ Data.Name || '--' }}（{{ FormatValue(Data) }}）<span v-if="Index + 1 !== SalePageDetail.Data.Variable.ShipmentFeePercentage.length">、</span></span>
        </p>
      </div>
      <div class="w-100 overflow-auto">
        <CRow class="text-nowrap mb-3" style="min-width: 900px;">
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">訂單數量</small><br>
              <strong class="h4">{{ SalePageDetail.TotalStatistics.TotalItems || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">訂單金額總計</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(SalePageDetail.TotalStatistics.TotalOrderAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">已付款金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(SalePageDetail.TotalStatistics.TotalPaymentAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">未付款金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(SalePageDetail.TotalStatistics.TotalUnPaymentAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">總分潤金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(SalePageDetail.TotalStatistics.CommissionAmount || 0) }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </div>
      <CDataTable
        id="OrderHistory"
        addTableClasses="small text-nowrap"
        :items="SalePageDetail.List"
        :fields="OrderListField"
        :loading="Loading"
        :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
        responsive
        hover
        striped
      >
        <template #OrderNum="{item}">
          <td>
            <a :href="`/order/detail/${item.OrderNum}`" target="_blank">{{item.OrderNum}}</a>
          </td>
        </template>
        <template #MemberName="{item}">
          <td>
            {{ item.MemberInfo.Name }}
          </td>
        </template>
        <template #Origin="{item}">
          <td>
            {{ $t('Order.OriginOptions.' + item.OriginType) }}<span v-if="item.OriginType !== 'Default'">({{ item.OriginPage ? item.OriginPage : '' }})</span>
          </td>
        </template>
        <template #TotalAmount="{item}">
          <td>
            ${{ $amount.format(item.TotalAmount) }}
          </td>
        </template>
        <template #CommissionAmount="{item}">
          <td>
            ${{ $amount.format(Math.round(item.CommissionAmount)) }}
          </td>
        </template>
        <template #PaymentStatus="{item}">
          <td>
            <CBadge :color="StatusClassOption[item.Status.Payment]">
              {{ $t('Order.PaymentStatusList.' + item.Status.Payment) }}
            </CBadge>
          </td>
        </template>
        <template #CreateTime="{item}">
          <td>
            {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
          </td>
        </template>
      </CDataTable>
      <CPagination :activePage.sync="SalePageDetail.Pagination.Page" :pages.sync="SalePageDetail.Pagination.TotalPages" @update:activePage="GetOrderList('SinglePage-' + SalePageDetail.Data.Slug, true)"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Navigation.Channel/SinglePage') }}: {{ SalePageDetail.Data ? SalePageDetail.Data.Name : '--' }}（{{ SalePageDetail.Data ? SalePageDetail.Data.Slug : '--' }}）</h5>
          <CButtonClose @click="SalePageDetailModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="ExportSalePageOrderList" color="info" :disabled="Loading">{{ $t('Global.ExportToCSV') }}</CButton>
          <CButton @click="SalePageDetailModal = false" color="light">{{ $t('Global.Close') }}</CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "銷售頁盈利總表"
}
}
</route>

<script>
import { CChartHorizontalBar, CChartDoughnut } from '@coreui/vue-chartjs'
export default {
  name: 'OrderSaleReport',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    CChartHorizontalBar,
    CChartDoughnut
  },
  data() {
    return {
      Loading: false,
      FilterModel: false,
      SalePageDetailModal: false,
      SalePageDetail: {
        Data: {
          Variable: {
            PaymentFeePercentage: [],
            ShipmentFeePercentage: []
          }
        },
        List: [],
        Pagination: {
          Page: 1,
          PerPage: 10,
          TotalItems: 0,
          TotalPages: 0
        },
        SubTotalStatistics: {},
        TotalStatistics: {}
      },
      Submit: false,
      ShowFilter: 'All',
      TotalStatistics: {},
      SearchFilter: {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01 00:00:00')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD 23:59:59')).unix() * 1000,
        Origin: [],
        OriginRegex: '',
        Country: [],
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      },
      ViewType: 'Table',
      ViewTypeOptions: [
        {
          value: 'Table',
          label: '表格'
        },
        {
          value: 'Chart',
          label: '圖表'
        }
      ],
      SearchFilterOriginMode: 'IN',
      Color: [
        '#4184f3', '#7cb442', '#ff5722', '#ff8f00', '#aa46bb',
        '#25abc0', '#9d9c23', '#5b6abf', '#ef6191', '#10786a',
        '#c11c5a', '#fa937f', '#34c0ff'
      ],
      TempReporting: [],
      PaymentReporting: [],
      LogisticsReporting: [],
      // Reporting: [],
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      ChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      },
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      }
    }
  },
  computed: {
    PageList() {
      return this.$store.state.landingPageList.filter(item => item.value !== 'Default') || []
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(value => {
        return {
          value,
          label: this.$t('Logistics.CountryOptions')[value]
        }
      })
    },
    ChartLabels() {
      return ['已付款訂單金額']
    },
    ChartDataSet() {
      return this.Reporting.map((item, index) => {
        return {
          label: item.PageName,
          borderColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          backgroundColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          fill: false,
          data: [item.PaymentAmount],
        }
      })
    },
    ChartPageLabels() {
      return this.Reporting.map((item) => item.PageName)
    },
    ChartDoughnutDataSet () {
      return [
        {
          backgroundColor: this.Reporting.map((item, index) => index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index]),
          data: this.Reporting.map((item) => item.TotalItems),
        }
      ]
    },
    Field() {
      return [
        {key: 'PageName', label: '銷售頁名稱'},
        {key: 'TotalItems', label: '訂單筆數'},
        {key: 'TotalAmount', label: '訂單金額'},
        {key: 'PaymentItems', label: '已付款筆數'},
        {key: 'PaymentAmount', label: '已付款訂單金額'},
        {key: 'UnPaymentItems', label: '未付款筆數'},
        {key: 'UnPaymentAmount', label: '未付款訂單金額'},
        {key: 'ReturnPaymentItems', label: '已退款筆數'},
        {key: 'ReturnPaymentAmount', label: '已退款訂單金額'},
        {key: 'SalePaymentFee', label: '已扣付款手續費'},
        {key: 'SaleShipmentFee', label: '已扣配送手續費'},
        {key: 'SaleReceiverAmount', label: '分潤金額'}
      ]
    },
    OrderListField () {
      return [
        {key: 'OrderNum', label: '訂單編號'},
        {key: 'MemberName', label: '購買人名稱'},
        {key: 'TotalAmount', label: '訂單金額'},
        {key: 'CommissionAmount', label: '訂單分潤'},
        {key: 'PaymentStatus', label: '付款狀態'},
        {key: 'CreateTime', label: '建立時間'}
      ]
    },
    TotalSaleReceiverAmount () {
      return this.Reporting.map(item => item.SaleReceiverAmount).reduce((a, c) => a + c, 0)
    },
    Reporting() {
      if (this.PageList.length === 0) {
        return []
      }
      return this.TempReporting.filter(item => item._id && item._id !== 'Default').map((item) => {
        // 銷售頁資料
        item.PageData = this.PageList.find(page => page.value === item._id) || {
          label: item._id.replace('SinglePage-', ''),
          value: item._id.replace('SinglePage-', ''),
          Variable: {}
        }
        item.PageName = item.PageData.label

        // 付款方式統計資料
        const PaymentFeePercentage = item.PageData.Variable?.PaymentFeePercentage || []
        item.SalePaymentFee = this.PaymentReporting.filter(payment => payment._id.CartType === item._id).map(payment => {
          // const PaymentFeeSetting = PaymentFeePercentage.find(object => object.ID === payment._id.PaymentID) || {
          //   Type: 'Percent',
          //   Value: 0
          // }
          // return (Math.round(PaymentFeeSetting.Type === 'Price' ? parseFloat(PaymentFeeSetting.Value || '0')  * payment.PaymentItems : (parseFloat(PaymentFeeSetting.Value || '0') / 100) * payment.PaymentAmount))
          return PaymentFeePercentage.filter(object => object.ID === payment._id.PaymentID).map(object => {
            return (Math.round(object.Type === 'Price' ? parseFloat(object.Value || '0')  * payment.PaymentItems : (parseFloat(object.Value || '0') / 100) * payment.PaymentAmount))
          }).reduce((a, c) => a + c, 0)
        }).reduce((a, c) => a + c, 0)

        // 配送方式統計資料
        const ShipmentFeePercentage = item.PageData.Variable?.ShipmentFeePercentage || []
        item.SaleShipmentFee = this.LogisticsReporting.filter(logistics => logistics._id.CartType === item._id).map(logistics => {
          // const ShipmentFeeSetting = ShipmentFeePercentage.find(object => object.ID === logistics._id.LogisticsID) || {
          //   Type: 'Percent',
          //   Value: 0
          // }
          // return (Math.round(ShipmentFeeSetting.Type === 'Price' ? parseFloat(ShipmentFeeSetting.Value || '0') * logistics.PaymentItems : (parseFloat(ShipmentFeeSetting.Value || '0') / 100) * logistics.PaymentAmount))

          return ShipmentFeePercentage.filter(object => object.ID === logistics._id.LogisticsID).map(object => {
            return (Math.round(object.Type === 'Price' ? parseFloat(object.Value || '0')  * logistics.PaymentItems : (parseFloat(object.Value || '0') / 100) * logistics.PaymentAmount))
          }).reduce((a, c) => a + c, 0)
        }).reduce((a, c) => a + c, 0)

        // 分潤金額
        item.SaleReceiverAmount = Math.round((item.PaymentAmount - item.SalePaymentFee - item.SaleShipmentFee) * (parseFloat(item.PageData.Variable?.CommissionPercentage || '0') / 100))

        return item
      }).sort((a, b) => b.TotalAmount - a.TotalAmount)
    }
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      this.FilterModel = false
      return Promise.all([
        this.GetReporting()
      ]).then(() => {
        return true
      }).catch((err) => {
        console.log(err)
        throw err
      })
    },
    GetReporting() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000,
        Site: this.$store.state.user.permission.Project
      }
      if (Array.isArray(this.SearchFilter.Origin) &&this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.OriginRegex) {
        Data['Variable.CartType_REGEX'] = this.SearchFilter.OriginRegex
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/reporting/cartType',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.TotalStatistics = data.TotalStatistics
        this.TempReporting = data.Data
        this.PaymentReporting = data.PaymentData
        this.LogisticsReporting = data.LogisticsData
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ChangeCountry(newTag, id) {
      id = id.replace('SearchFilter.', '')
      const TaiwanCode = ['TW', 'TW-MAIN', 'TW-OUTLYING']
      if (TaiwanCode.includes(newTag.value)) {
        TaiwanCode.splice(TaiwanCode.findIndex(code => code === newTag.value), 1)
        TaiwanCode.forEach((code) => {
          this.SearchFilter[id].push({
            label: this.$t('Logistics.CountryOptions')[code],
            value: code
          })
        })
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01')).unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        Origin: [],
        OriginRegex: '',
        Country: []
      }
      this.Init()
    },
    Print() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000,
        Site: this.$store.state.user.permission.Project
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/order/reporting/cartType',
          Data,
          type: 'salePage'
        }
      }).then((response) => {
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `銷售頁盈利總表-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    async GetOrderList(slug, changePage = false) {
      this.SalePageDetailModal = true
      this.Loading = true
      if (!changePage) {
        this.SalePageDetail.Pagination = {
          Page: 1,
          PerPage: 10,
          TotalItems: 0,
          TotalPages: 0
        }
        this.SalePageDetail.List = []
        this.SalePageDetail.SubTotalStatistics = {}
        this.SalePageDetail.TotalStatistics = {}
        this.SalePageDetail.Data = {
          Variable: {
            PaymentFeePercentage: [],
            ShipmentFeePercentage: []
          }
        }
        this.SalePageDetail.Data = await this.$store.dispatch('InnerRequest', {
          url: '/page/shop/detail',
          method: 'get',
          params: {
            slug: slug.replace('SinglePage-', '')
          }
        }).then(async ({ data }) => {
          return data.detail
        })
      }
      const RequestQuery = {
        Taxonomy: 'ProductOrder',
        Page: this.SalePageDetail.Pagination.Page,
        PerPage: this.SalePageDetail.Pagination.PerPage,
        Order: this.SalePageDetail.Order,
        Origin: slug
      }
      if (this.SalePageDetail.Data) {
        RequestQuery.StartTime = this.$dayjs(this.SalePageDetail.Data.Variable.StartTime).unix()
        RequestQuery.EndTime = this.$dayjs(this.SalePageDetail.Data.Variable.EndTime).unix()
      } else {
        RequestQuery.SearchAllTimeRange = true
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/list',
        method: 'get',
        params: RequestQuery
      }).then(({ data }) => {
        this.Loading = false
        this.SalePageDetail.List = data.list.map(item => {
          item.SalePaymentFee = (this.SalePageDetail.Data?.Variable?.PaymentFeePercentage || []).filter(object => object.ID === item.Payment.ID).map(object => {
            return object.Type === 'Price' ? parseFloat(object.Value || '0') : (parseFloat(object.Value || '0') / 100) * item.TotalAmount
          }).reduce((a, c) => a + c, 0)

          item.SaleShipmentFee = (this.SalePageDetail.Data?.Variable?.ShipmentFeePercentage || []).filter(object => object.ID === item.Logistics.ID).map(object => {
            return object.Type === 'Price' ? parseFloat(object.Value || '0') : (parseFloat(object.Value || '0') / 100) * item.TotalAmount
          }).reduce((a, c) => a + c, 0)

          item.CommissionAmount = item.Status.Payment === 'Y' ? (item.TotalAmount - item.SalePaymentFee - item.SaleShipmentFee) * parseFloat(this.SalePageDetail.Data?.Variable?.CommissionPercentage || '0') / 100 : 0
          return item
        })
        this.SalePageDetail.SubTotalStatistics = data.subTotalStatistics || {}
        this.SalePageDetail.TotalStatistics = {
          ...data.totalStatistics,
          ...this.SalePageDetail.TotalStatistics
        }
        this.SalePageDetail.Pagination = {
          Page: data.pagination.Page,
          PerPage: data.pagination.PerPage.toString(),
          TotalItems: data.pagination.TotalItems,
          TotalPages: data.pagination.TotalPages
        }
        // 取得銷售頁統計資訊
        if (!changePage) {
          const Data = {
            'Variable.CartType': slug
          }
          if (this.SalePageDetail.Data) {
            Data.StartTime = this.$dayjs(this.SalePageDetail.Data.Variable.StartTime).unix()
            Data.EndTime = this.$dayjs(this.SalePageDetail.Data.Variable.EndTime).unix()
          }
          return this.$store.dispatch('InnerRequest', {
            url: '/service/action',
            method: 'post',
            data: {
              URL: '/order/reporting/cartType',
              Data
            }
          }).then(({ data: report }) => {
            // 付款方式統計資料
            const PaymentFeePercentage = this.SalePageDetail.Data?.Variable?.PaymentFeePercentage || []
            const SalePaymentFee = report.PaymentData.filter(payment => payment._id.CartType === slug).map(payment => {
              return PaymentFeePercentage.filter(object => object.ID === payment._id.PaymentID).map(object => {
                return (Math.round(object.Type === 'Price' ? parseFloat(object.Value || '0')  * payment.PaymentItems : (parseFloat(object.Value || '0') / 100) * payment.PaymentAmount))
              }).reduce((a, c) => a + c, 0)
            }).reduce((a, c) => a + c, 0)

            // 配送方式統計資料
            const ShipmentFeePercentage = this.SalePageDetail.Data?.Variable?.ShipmentFeePercentage || []
            const SaleShipmentFee = report.LogisticsData.filter(logistics => logistics._id.CartType === slug).map(logistics => {
              return ShipmentFeePercentage.filter(object => object.ID === logistics._id.LogisticsID).map(object => {
                return (Math.round(object.Type === 'Price' ? parseFloat(object.Value || '0')  * logistics.PaymentItems : (parseFloat(object.Value || '0') / 100) * logistics.PaymentAmount))
              }).reduce((a, c) => a + c, 0)
            }).reduce((a, c) => a + c, 0)

            // 分潤金額
            const CommissionAmount = Math.round((data.totalStatistics.TotalPaymentAmount - SalePaymentFee - SaleShipmentFee) * (parseFloat(this.SalePageDetail.Data.Variable?.CommissionPercentage || '0') / 100))

            this.SalePageDetail.TotalStatistics = {
              ...data.totalStatistics,
              CommissionAmount
            }
          })
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ExportSalePageOrderList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/report/export/salePage/detail',
        method: 'post',
        data: {
          Slug: this.SalePageDetail.Data.Slug,
        }
      }).then(({ data }) => {
        this.Loading = false
        const BinaryString = window.atob(data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `${this.SalePageDetail.Data.Slug}-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    FormatValue (item) {
      switch (item.Type) {
        case 'Price':
          return '$' + item.Value
        case 'Percent':
          return item.Value + '%'
        default:
          return item.Value
      }
    }
  }
}
</script>

<style lang="scss">
#OrderSaleReport tbody td:first-child {
  vertical-align: middle;
}

.WidgetList {
  .card-body {
    small {
      height: 36px;
      max-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
